exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-contacto-index-js": () => import("./../../../src/pages/contacto/index.js" /* webpackChunkName: "component---src-pages-contacto-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-normas-index-js": () => import("./../../../src/pages/normas/index.js" /* webpackChunkName: "component---src-pages-normas-index-js" */),
  "component---src-pages-normas-moderadores-js": () => import("./../../../src/pages/normas/moderadores.js" /* webpackChunkName: "component---src-pages-normas-moderadores-js" */),
  "component---src-pages-normas-usuarios-js": () => import("./../../../src/pages/normas/usuarios.js" /* webpackChunkName: "component---src-pages-normas-usuarios-js" */),
  "component---src-pages-noticias-index-js": () => import("./../../../src/pages/noticias/index.js" /* webpackChunkName: "component---src-pages-noticias-index-js" */),
  "component---src-pages-noticias-nuevas-mejoras-js": () => import("./../../../src/pages/noticias/nuevas-mejoras.js" /* webpackChunkName: "component---src-pages-noticias-nuevas-mejoras-js" */),
  "component---src-pages-privacidad-index-js": () => import("./../../../src/pages/privacidad/index.js" /* webpackChunkName: "component---src-pages-privacidad-index-js" */),
  "component---src-pages-radio-index-js": () => import("./../../../src/pages/radio/index.js" /* webpackChunkName: "component---src-pages-radio-index-js" */),
  "component---src-pages-salas-de-chat-index-js": () => import("./../../../src/pages/salas-de-chat/index.js" /* webpackChunkName: "component---src-pages-salas-de-chat-index-js" */),
  "component---src-pages-status-index-js": () => import("./../../../src/pages/status/index.js" /* webpackChunkName: "component---src-pages-status-index-js" */),
  "component---src-templates-chat-room-template-js": () => import("./../../../src/templates/chatRoomTemplate.js" /* webpackChunkName: "component---src-templates-chat-room-template-js" */)
}

