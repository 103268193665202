/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// Importaciones de módulos y estilos.
import React from "react"
import "animate.css/animate.min.css"
import client from "./src/graphql/ApolloClient"
import { ApolloProvider } from "@apollo/client"

// Envolver el elemento raíz en un proveedor de Apollo para gestionar el estado de GraphQL.
export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>{element}</ApolloProvider>
)
