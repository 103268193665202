import { createClient } from "graphql-ws"
import { getMainDefinition } from "@apollo/client/utilities"
import { GraphQLWsLink } from "@apollo/client/link/subscriptions"
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  split,
} from "@apollo/client"
import { eventEmitter } from "./eventEmitter"

const httpLink = createHttpLink({
  uri: process.env.GATSBY_API_URL, // Asegúrate de usar el prefijo GATSBY_ para exponer variables al navegador
})

let wsLink

// Función para crear un nuevo WebSocketLink
function createWsLink() {
  if (typeof window !== "undefined") {
    // Usar la implementación nativa de WebSocket en el navegador
    wsLink = new GraphQLWsLink(
      createClient({
        url: process.env.GATSBY_API_URL_SUBSCRIPTIONS,
        connectionParams: () => ({
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        }),
        reconnect: true,
        on: {
          disconnected: (code, reason) => {
            eventEmitter.emit("wsDisconnected")
          },
          error: error => {
            eventEmitter.emit("wsDisconnected", error)
          },
          message: info => {
            eventEmitter.emit("wsConnected", info)
          },
        },
      })
    )
  }
}

createWsLink() // Inicializar wsLink si es posible

const link = wsLink
  ? split(
      ({ query }) => {
        const definition = getMainDefinition(query)
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        )
      },
      wsLink,
      httpLink
    )
  : httpLink

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
})

export function restartWebSocketConnection() {
  if (typeof window !== "undefined") {
    createWsLink()
    client.setLink(
      split(
        ({ query }) => {
          const definition = getMainDefinition(query)
          return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
          )
        },
        wsLink,
        httpLink
      )
    )
  }
}

export function disposeWebSocket() {
  if (wsLink && wsLink.client) {
    wsLink.client.dispose()
  }
}

export default client
